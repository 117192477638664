import { createSlice } from "@reduxjs/toolkit";

export const entriesSlice = createSlice({
    name: 'entries',
    initialState: {
        value: []
    },
    reducers: {
        add: (state) => {
            state.value = [...state.value, {id:state.value.length+1, person:'', amount:''}];
        },
        updatePerson: (state, data) => {
            state.value[data.payload[0]].person = data.payload[1];
        },
        updateAmount: (state, data) => {
            state.value[data.payload[0]].amount = data.payload[1];
        },
        remove: (state, i) => {
            state.value.splice(i.payload, 1);
            state.value = state.value.map((item, ind) => {return {id:ind+1, person:item.person, amount:item.amount}});
        }

    }
});

export const { add, updatePerson, updateAmount, remove } = entriesSlice.actions;
export default entriesSlice.reducer;