import './App.css';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Box, TextField } from '@mui/material';
import { add, updatePerson, updateAmount, remove } from './slices';
import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PDFDownloadLink, Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

const App = () => {

  const entries = useSelector((state) => state.entries.value)
  const dispatch = useDispatch()

  const [addP, setAddp] = useState(false);
  const [owesCalc, setOwesCalc] = useState({});
  const [total, setTotal] = useState({});

  const styles = StyleSheet.create({
    page: {  backgroundColor: '#968a65'},
    section: { textAlign: 'center', margin: 30 },
    image: {height: '100px', width:'100px'}
  });

  // Create Document Component
  const MyDocument = () => {
    return(
    <Document>
      <Page size="A4" style={styles.page}>
          <View>
            <Image src={require('./assets/madspong.png')} style={styles.image}></Image>
            <Text style={{textAlign: 'center', textDecoration: 'underline', transform:'translateY(-50)' }}> BludHound Receipt </Text>
          </View>

          <View style={styles.section}>
              {
                Object.keys(owesCalc).map((item) => {
                  return (
                    <div>
                    {
                      owesCalc[item].map((i) => {
                        return <Text>{item} owes ${i.amount} to {i.person}</Text>
                      })
                    }
                    </div>
                  )
                })
              }
          </View>
      </Page>
    </Document>
  )
  }

  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.scrollHeight;
  }, [addP]);

  const calculate = () => {
    var all = entries.map((item) => {return {id:item.id, person:item.person, amount: item.amount ? parseFloat(parseFloat(item.amount).toFixed(2)) : 0}});
    all = all.sort((a,b) => {return a.amount > b.amount ? -1 : 1});
    
    const n = all.length;
    var owes = {};
    var total = {};
    all.forEach(item => owes[item.person] = []);
    for (let i = 0; i < n; ++i) {
      for (let j = i+1; j < n; ++j) {
        owes[all[j].person].push({person: all[i].person, amount:parseFloat((all[i].amount/n)-(all[j].amount/n)).toFixed(2)});
      }
    }

    for (var person in owes) {
      var totalAmount = 0;
      for(var person2 in owes[person]) {
        totalAmount += parseFloat(owes[person][person2].amount);
      }
      total[person] = totalAmount;
    }

    setTotal(total);
    setOwesCalc(owes);
  }

  return (
    <div className='app'>
      <Box display='flex'>
        <img src={require('./assets/madspong.png')} alt='spong' height='100px'></img>
        <h1>BludHound</h1>
      </Box>
      <p>Person, amount, sniff out who owes who what. Results at the bottom.</p>
        {
          entries.map((item) => {
            return <Entry key={item.id} i={item.id} />
          })
        }
        <Box display='flex' padding='30px'>
          <Button
            variant="contained"
            style={{marginInline:'10px '}}
            onClick={() => {
              setAddp(!addP);
              dispatch(add());
            }}
          >
            Add Person
          </Button>
          <Button
            variant="contained"
            style={{marginInline:'10px ', backgroundColor:'green'}}
            onClick={() => {
              calculate();
            }}
          >
            Calculate
          </Button>
        </Box>
        {
          Object.keys(owesCalc).length > 0 ?
          <div>
            <h2>Totals:</h2>
          </div>
          : <></>
        }
        {
          Object.keys(owesCalc).map((item) => {
            return (
              total[item] > 0 ?
              <div className='accordion'>
                <Accordion className='accordionSummary'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">{item} - Total Owed: ${total[item].toFixed(2)} </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell><b>Who: </b></TableCell>
                            {owesCalc[item].map((i) => {
                              return <TableCell align="right">{i.person}</TableCell>
                            })
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
  
                          <TableRow key={item} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row"></TableCell>
                          {owesCalc[item].map((i) => {
                              return <TableCell align="right">${i.amount}</TableCell>
                            })
                            }
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </div>
              : <></>
            )
          })
        }
        {
          Object.keys(owesCalc).length > 0 ?
          <div>
            <>Click </>
            <PDFDownloadLink 
              document={<MyDocument />} 
              fileName="bludhound_receipt.pdf">
            {({ blob, url, loading, error }) =>
              loading ? '....' : 'here'
            }
            </PDFDownloadLink>
            <> to download a reciept of this calculation.</>
          </div>
          : <></>
        }
    </div>
  );
}

const Entry = ({ i }) => {

  const dispatch = useDispatch()

  return (
    <>
      <Box display='flex'>
        <p style={{ marginBottom: '1px' }}>Person {i}</p>
        <Field def={'Name'} i={i} />
        <Field def={'Amount Spent ($)'} type={'number'} i={i} />
        <Button
          variant="text"
          style={{ marginInline: '10px ', color: 'darkred' }}
          onClick={() => {
            dispatch(remove(i - 1))
          }}
        >
          Remove
        </Button>
      </Box>
    </>
  );
}

const Field = ({ def, type, i }) => {

  const dispatch = useDispatch()
  const entries = useSelector((state) => state.entries.value)

  return (
    <TextField
      label={def}
      variant="outlined"
      placeholder={def}
      type={type}
      value={type === 'number' ? entries[i - 1].amount : entries[i - 1].person}
      style={
        {
          marginTop: '30px',
          marginInline: '15px',
          backgroundColor: 'white',
          borderColor: 'black',
        }
      }
      onChange={(event) => {
        dispatch(type === 'number' ? updateAmount([i - 1, event.target.value]) : updatePerson([i - 1, event.target.value]));
      }}
    />
  );
}

export default App;
